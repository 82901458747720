<template>
  <div class="chats">
    <div class="page-header">
      <h1 class="page-title">Messages</h1>
      <div class="actions">
        <!-- Search -->
        <a-input-search
          size="large"
          placeholder="Search messages"
          v-model="search_query"
        />
        <!-- / Search -->

        <!-- <a-button
          icon="phone"
          size="large"
          class="btn-rounded"
          type="default"
          @click.prevent="$router.push('/call-for-help')"
          >Call For Help</a-button
        > -->
        <a-button
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          @click.prevent="openCreateChatModal"
          >New Chat</a-button
        >
      </div>
    </div>

    <create-chat-modal
      v-if="createChatModalInitialised"
      :visible="createChatModalVisible"
      @close-modal="closeCreateChatModal"
    ></create-chat-modal>

    <!-- List wrapper -->
    <div class="messages-list-wrapper">
      <!-- Loading -->
      <div class="loader" v-if="isLoadingCombined">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No chats -->
      <a-alert
        v-if="!isLoadingCombined && chatsToShow.length == 0"
        type="info"
        message="No chats to show"
      />
      <!-- / No chats -->

      <!-- Loaded -->
      <div class="chats-list" v-if="!isLoadingCombined && chatsToShow.length">
        <a-row type="flex" :gutter="20">
          <a-col :span="colSpan" v-for="chat in chatsToShowOrdered" :key="chat.id">
            <chat-list-item :chat="chat"></chat-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->

      <!-- <a-list
        :loading="isLoading"
        item-layout="horizontal"
        :data-source="chats"
      >
        <a-list-item slot="renderItem" slot-scope="item">
          <a-list-item-meta :description="getLastActivityHuman(item)">
            <router-link slot="title" :to="getChatUrl(item)">{{
              item.displayName
            }}</router-link>
            <a-avatar slot="avatar" :src="getChatAvatar(item)" />
          </a-list-item-meta>
        </a-list-item>
      </a-list> -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Chats from "../mixins/Chats";
import Dates from "../mixins/Dates";
import CreateChatModal from "../components/Chats/CreateChatModal.vue";
import ChatListItem from "./Chats/ChatListItem.vue";
import RequiresNonHiddenPresences from "../mixins/RequiresNonHiddenPresences";
export default {
  name: "Chats",
  mixins: [Chats, Dates, RequiresNonHiddenPresences],
  components: { CreateChatModal, ChatListItem },
  data() {
    return {
      createChatModalInitialised: false,
      createChatModalVisible: false,
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  created() {
    this.setSearchQuery("");

    if (this.isChatDisabledForAllOrganisations) {
      this.$message.error('Chat is disabled for your organisation');
      this.$router.push('/');
    }
    // this.loadChats();
  },
  computed: {
    // ...mapGetters("missionControl", {
    //   organisations: "organisations",
    // }),

    ...mapGetters("chats", {
      chatsToShow: "chatsToShow",
      chatsToShowOrdered: "chatsToShowOrdered",
      isLoading: "isLoading",
      searchQuery: "searchQuery",
    }),

    ...mapGetters("missionControl", {
      isLoadingMissionControl: "isLoading",
      isChatDisabledForAllOrganisations: "isChatDisabledForAllOrganisations"
    }),

    search_query: {
      set(val) {
        this.setSearchQuery(val);
      },
      get() {
        return this.searchQuery;
      },
    },

    isLoadingCombined() {
      return this.isLoading || this.isLoadingMissionControl;
    },

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },
  },
  methods: {
    ...mapActions("chats", {
      loadChats: "loadChats",
      setSearchQuery: "setSearchQuery",
    }),

    // getOrganisationById(id) {
    //   return _.find(this.organisations, { id });
    // },

    // getChatUrl(chat) {
    //   let organisation = this.getOrganisationById(chat.ownerId);
    //   let tenantId = organisations.getOrganisationTenantId(organisation);
    //   return "/chats/" + tenantId + "/" + chat.id;
    // },

    openCreateChatModal() {
      if (!this.createChatModalInitialised) {
        this.createChatModalInitialised = true;
      }
      this.$nextTick(() => {
        this.createChatModalVisible = true;
      });
    },

    closeCreateChatModal() {
      this.createChatModalVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.button-margin-left {
  margin-left: 10px;
}

.chat-list-item {
  margin-bottom: 15px;
}

.chats-list {
  padding-bottom: 30px;
}
</style>